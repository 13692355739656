import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { PolycardContext, Card } from '@polycard/card';

const GridLayout = ({ items, context, namespace, preload, deviceType, countryConfig, preloadNumImage }) => (
  <div className={classNames(`${namespace}__grid`)}>
    <PolycardContext
      contextValue={{ ...context, title_tag: 'h2' }}
      deviceType={deviceType}
      countPreloadImages={preloadNumImage}
      thousandSeparator={countryConfig?.thousands_separator}
      decimalSeparator={countryConfig?.decimal_separator}
    >
      {items.map((item, index) => (
        <Card
          polycard={item}
          key={item?.metadata?.id}
          preload={preload && index < preloadNumImage}
          cardNumber={index + 1}
          className={classNames(`${namespace}__item`)}
        />
      ))}
    </PolycardContext>
  </div>
);

GridLayout.propTypes = {
  context: PropTypes.shape({}),
  countryConfig: PropTypes.shape({
    decimal_separator: PropTypes.string,
    thousands_separator: PropTypes.string,
  }),
  deviceType: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  namespace: PropTypes.string,
  preload: PropTypes.bool,
  preloadNumImage: PropTypes.number,
};

export default GridLayout;
