import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Button from '@andes/button';

import Layout from './grid-layout';
import { useUser } from '../context/user';

const namespace = 'ui-search-layout--grid';

const BestSellersItems = ({ component, className, preload, deviceType, countryConfig, preloadNumImage }) => {
  const { title, polycards, polycard_context, button_action } = component;
  const { loggedIn, loginUrl } = useUser();
  const redirect = (url) => {
    if (loggedIn) {
      window.location.href = url;

      return;
    }

    window.location.href = `${loginUrl.split('&go=')[0]}&go=${url}&loginType=explicit`;
  };

  return (
    <section
      className={classNames(`${namespace}__container`, {
        [`${className}__layout--grid`]: className,
      })}
    >
      {title && <h2 className={classNames(`${namespace}__title`)}>{title}</h2>}
      <Layout
        items={polycards}
        context={polycard_context}
        namespace={namespace}
        loggedIn={loggedIn}
        preload={preload}
        deviceType={deviceType}
        countryConfig={countryConfig}
        preloadNumImage={preloadNumImage}
      />
      {button_action && (
        <Button
          className={classNames(`${namespace}__button`)}
          fullWidth
          onClick={() => {
            redirect(button_action.url);
          }}
        >
          {button_action.text}
        </Button>
      )}
    </section>
  );
};

BestSellersItems.propTypes = {
  className: PropTypes.string,
  component: PropTypes.shape({
    button_action: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    polycard_context: PropTypes.shape({}),
    polycards: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
  }),
  countryConfig: PropTypes.shape({}),
  deviceType: PropTypes.string,
  preload: PropTypes.bool,
  preloadNumImage: PropTypes.number,
};

export default BestSellersItems;
