/* eslint-disable react/prop-types */
/**
 * Module dependencies
 */
import React from 'react';

import { Style } from 'nordic/style';
import { MeliGA } from 'nordic/analytics/meli-ga';
import { Hotjar } from 'nordic/hotjar';

import Metadata from '../../../../../components/metadata';
import GoogleTagManager from '../../../../../components/google-tag-manager';
import useHotjarActionsByCookie from '../../../../../hooks/use-hotjar-actions-by-cookie';

/**
 * View Component
 */
const Category = ({ children, siteId, cookies, deviceType, initialState, hotjar }) => {
  useHotjarActionsByCookie(hotjar);

  return (
    <>
      <Style href={`search.${deviceType}.cat.css`} critical={deviceType === 'mobile'} />
      <Metadata {...initialState.seo} {...initialState.canonical_info} />
      {children}
      <MeliGA {...initialState.analytics_track} />
      <Hotjar {...hotjar} />
      <GoogleTagManager
        siteId={siteId}
        results={initialState.results}
        platform="CAT"
        cookies={cookies}
        queryInitialState={initialState.query}
      />
    </>
  );
};

/**
 * Inject i18n context as props into View.
 */
export default Category;
